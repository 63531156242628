import { Injectable } from '@angular/core';

import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { ModalManager } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { AlertController, Platform } from '@ionic/angular';

export interface IConfirmationMessageConfig {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText?: string | null;
}

@Injectable({ providedIn: 'root' })
export class ConfirmationController {
  private confirmPromise!: Promise<boolean>;
  private openConfirmation = false;

  constructor(private platform: Platform, private alertCtrl: AlertController, private modalManager: ModalManager) {}

  confirmAction(messageConfig: IConfirmationMessageConfig): Promise<boolean> {
    if (this.openConfirmation) return this.confirmPromise;

    let resolveFn: (confirmed: boolean) => void = () => {};
    this.confirmPromise = new Promise<boolean>((resolve) => {
      resolveFn = (confirmed: boolean) => {
        this.openConfirmation = false;
        return resolve(confirmed);
      };
    });

    const isMobile = this.platform.is('cordova') && !this.platform.is('ipad');
    this.openConfirmation = true;
    if (isMobile) {
      this.confirmByAlert(messageConfig, resolveFn);
    } else {
      this.confirmByModal(messageConfig, resolveFn);
    }

    return this.confirmPromise;
  }

  private async confirmByAlert(messageConfig: IConfirmationMessageConfig, resolveFn: (confirmed: boolean) => void) {
    const buttons: MSafeAny[] = [
      {
        text: messageConfig.confirmButtonText,
        cssClass: 'confirmation',
        handler: () => {
          resolveFn(true);
        }
      }
    ];

    if (messageConfig.cancelButtonText) {
      buttons.unshift({
        text: messageConfig.cancelButtonText,
        role: 'cancel',
        handler: () => {
          resolveFn(false);
        }
      });
    }

    const alert = await this.alertCtrl.create({
      header: messageConfig.title,
      message: messageConfig.message,
      buttons
    });
    alert.present();
  }

  private confirmByModal(messageConfig: IConfirmationMessageConfig, resolveFn: (confirmed: boolean) => void) {
    const buttons = [
      {
        text: messageConfig.confirmButtonText,
        type: 'primary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
          resolveFn(true);
        }
      }
    ];

    if (messageConfig.cancelButtonText) {
      buttons.unshift({
        text: messageConfig.cancelButtonText,
        type: 'secondary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
          resolveFn(false);
        }
      });
    }

    this.modalManager.openMatModal(ConfirmationModalComponent, {
      data: {
        title: messageConfig.title,
        contents: [messageConfig.message],
        buttons
      },
      disableClose: true,
      panelClass: ['media-content-modal', 'confirmation-modal']
    });
  }
}
