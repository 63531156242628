import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';

import {
  DEFAULT_PAGE_WHEN_LOGGED,
  PAGES,
  ROOT_PAGES,
  DEFAULT_PAGE,
  DETAIL_PAGES_PARENT,
  PREVIOUS_PAGE
} from '@app/shared/enums/pages/pages.enum';
import { getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { InternalRoute, RouteHelper } from '@app/shared/helpers/route.helper';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';
import { AuthService } from '@services/auth/auth.service';
import { Logger } from '@services/logger/logger.service';
import { NavigationEvents } from '@services/navigation/navigation.service';
import { STORAGE_CONSTANTS } from '@services/storage/storage.const';
import { StorageService } from '@services/storage/storage.service';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NavigationHelperService {
  private readonly logger = new Logger('NavigationHelperService');
  private previousUrl!: string;
  private currentUrl: string;
  private nextUrl!: string;

  constructor(private authService: AuthService, private router: Router, private storage: StorageService) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.logger.debug(`>>>> Previous url: "${this.previousUrl}", Current url: "${this.currentUrl}"`);
      }

      if (event instanceof NavigationStart) {
        this.nextUrl = event.url;
        this.logger.debug(`>>>> event url: "${event}", Current url: "${this.nextUrl}"`);
      }
    });
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  getCurrentUrl(): string {
    return this.currentUrl;
  }

  getNextUrl(): string {
    return this.nextUrl;
  }

  async deepLinkRedirect(internalRoute: InternalRoute): Promise<void> {
    if (internalRoute.path === ROOT_PAGES.MOBILE_WEB_LANDING) {
      return;
    }

    if (internalRoute.path.includes(ROOT_PAGES.VIEWER)) {
      await this.redirect(NavigationEvents.Push, internalRoute, true);
      return;
    }

    if (internalRoute.path === ROOT_PAGES.AUTHENTICATION) {
      await this.storeRedirect(internalRoute.redirectAfterLogin as InternalRoute);

      const active = this.router.url;
      this.logger.debug(`currentActive page is ${active}`);

      if (active.indexOf(ROOT_PAGES.AUTHENTICATION) === -1) {
        await this.doRedirect(NavigationEvents.Push, internalRoute);
      }

      return;
    }

    if (this.authService.isLoggedIn()) {
      await this.redirect(NavigationEvents.Push, internalRoute);
      return;
    }

    await this.storeRedirect(internalRoute);
  }

  redirect(
    navigationType: NavigationEvents,
    internalRoute: InternalRoute | undefined,
    cleanStorageRedirect = false
  ): Promise<void> {
    const hasPath = internalRoute && internalRoute.path;
    const isPop = navigationType === NavigationEvents.Pop;

    this.logger.debug(
      `Trying to ${NavigationEvents[navigationType]} to the internalRoute: ${JSON.stringify(internalRoute)}`
    );

    if (!hasPath && !isPop) {
      return Promise.reject('Invalid route');
    }

    const isPublicPage = hasPath && RouteHelper.isPublic(internalRoute);
    const userIsLogged = this.authService.isLoggedIn();

    this.logger.debug(`Authentication Status: ${this.authService.getStatus()}`);

    if (!(isPop || isPublicPage || userIsLogged)) {
      const error = `Can't perform redirect because isPublicPage: ${isPublicPage} or userIsLogged: ${userIsLogged}`;
      this.logger.debug(error);
      return Promise.reject(error);
    }
    return this.doRedirect(navigationType, internalRoute as InternalRoute, cleanStorageRedirect);
  }

  getRedirect(): Promise<InternalRoute> {
    return this.storage.get(STORAGE_CONSTANTS.REDIRECT_INTERNAL_ROUTE);
  }

  async storeRedirect(internalRoute: InternalRoute | undefined): Promise<void> {
    if (!internalRoute) {
      return Promise.resolve();
    }

    try {
      await this.storage.set(STORAGE_CONSTANTS.REDIRECT_INTERNAL_ROUTE, internalRoute);
      this.logger.debug(`storeRedirect:setItems: OK --> ${JSON.stringify(internalRoute)}`);
      Promise.resolve();
    } catch (error) {
      this.logger.error('storeRedirect:setItems: KO');
      Promise.reject(error);
    }
  }

  async cleanRedirect(): Promise<void> {
    this.logger.debug(`Cleaning redirect stored: ${await this.getRedirect()}`);
    return this.storage.remove(STORAGE_CONSTANTS.REDIRECT_INTERNAL_ROUTE);
  }

  async doRedirect(
    navigationType: NavigationEvents,
    internalRoute: InternalRoute,
    cleanStorageRedirect = false
  ): Promise<void> {
    try {
      this.logger.debug(`doRedirect with args: ${JSON.stringify(internalRoute)}`);
      await domChanges();

      const navigationExtras: NavigationExtras = this.getNavigationExtras(internalRoute);
      const extras = { ...navigationExtras, replaceUrl: true };

      switch (navigationType) {
        case NavigationEvents.SetRoot:
          // await this.navCtrl.navigateRoot(internalRoute.path, navigationExtras);
          await this.router.navigateByUrl(internalRoute.path, navigationExtras);
          break;
        case NavigationEvents.Push:
          // await this.router.navigateByUrl(internalRoute.path, navigationExtras);
          await this.router.navigate([internalRoute.path], extras);
          break;
        case NavigationEvents.PushRemove:
          await this.router.navigateByUrl(internalRoute.path, extras);
          break;
        case NavigationEvents.Pop:
        default:
          if (this.previousUrl && this.previousUrl !== '/') {
            await this.router.navigate([this.previousUrl], extras);
          } else {
            const pageId = this.currentUrl?.split('/').reduce((acc, curr) => acc || curr, '');
            if (PREVIOUS_PAGE[pageId]) {
              await this.router.navigateByUrl(PREVIOUS_PAGE[pageId]);
              // await this.navCtrl.navigateRoot(PREVIOUS_PAGE[pageId]);
            } else {
              this.logger.warn('Cant go back', internalRoute, this.currentUrl);
              await this.router.navigateByUrl(PAGES.HOME);
              // await this.navCtrl.navigateRoot(PAGES.HOME);
            }
          }
          break;
      }
    } catch (err) {
      this.logger.error(err);
      return Promise.reject(err);
    }

    if (cleanStorageRedirect) {
      return this.cleanRedirect();
    }

    return Promise.resolve();
  }

  getActiveRoute(): MSafeAny {
    const routerNamePosition = this.router.url.lastIndexOf('/');
    if (routerNamePosition === -1) {
      return '';
    }

    if (this.router.url.substring(1) === PAGES.OFFLINE) {
      return get(this.router.getCurrentNavigation(), 'extras.state.pageWhenOnline') ?? PAGES.OFFLINE;
    }

    const rootUrl = this.router.url.substring(1, routerNamePosition);
    if (rootUrl === '/' || rootUrl.includes('ptrab') || rootUrl.includes('mot') || rootUrl.includes('personal-data')) {
      return this.router.url.substring(routerNamePosition + 1);
    } else if (rootUrl.includes('model')) {
      return PAGES.MODEL;
    }

    return DETAIL_PAGES_PARENT[rootUrl] ?? rootUrl;
  }

  /**
   * @description Transform our internal route into Angular's router navigation extra
   */
  private getNavigationExtras(internalRoute: InternalRoute): NavigationExtras {
    const navigationExtras: NavigationExtras = {
      state: internalRoute ? internalRoute.navParams : {}
    };

    const item_id = get(navigationExtras, 'state.item_id');
    const comment_id = get(navigationExtras, 'state.comment_id');
    const commentIdToNestInPath = comment_id ? `/${comment_id}` : '';

    // Publication: Append Item Id to Url
    if (item_id && internalRoute.path === getUrlFromMap(PAGES.ITEM)) {
      internalRoute.path = `${PAGES.ITEM}/${item_id}${commentIdToNestInPath}`;
    }

    // Example: Append Item Id to Url
    if (item_id && internalRoute.path === getUrlFromMap(PAGES.ITEM_EXAMPLE)) {
      internalRoute.path = `${PAGES.ITEM_EXAMPLE}/${item_id}${commentIdToNestInPath}`;
    }

    // My publication: Append Item Id to Url
    if (item_id && internalRoute.path === getUrlFromMap(PAGES.ITEM_MY_PUBLICATION)) {
      internalRoute.path = `${PAGES.ITEM_MY_PUBLICATION}/${item_id}${commentIdToNestInPath}`;
    }

    return navigationExtras;
  }

  retryOnError(): Promise<void> {
    if (this.previousUrl && this.previousUrl.includes(PAGES.AUTHENTICATION)) {
      this.router.navigate([this.previousUrl], { replaceUrl: true });
      return Promise.resolve();
    }

    if (this.previousUrl) {
      return this.doRedirect(NavigationEvents.Push, { path: this.previousUrl }, false);
    }

    return this.doRedirect(NavigationEvents.SetRoot, { path: PAGES.AUTHENTICATION });
  }

  canTryPop() {
    return !RouteHelper.isErrorPage(this.previousUrl);
  }

  async getOnlineRedirectPage(pageWhenOnline?: MSafeAny): Promise<InternalRoute | MSafeAny> {
    const internalRoute: InternalRoute | MSafeAny = { path: null, cleanRedirect: false };
    if (pageWhenOnline) {
      internalRoute.path = pageWhenOnline;
      return internalRoute;
    }

    const storedRedirect = await this.getRedirect();

    if (storedRedirect) {
      storedRedirect.cleanRedirect = true;
    }

    const hasAdfsSession = await this.authService.hasAdfsSession();

    internalRoute.path = this.getDefaultNavigationPage(hasAdfsSession);

    if (hasAdfsSession) {
      return storedRedirect || internalRoute;
    }

    if (storedRedirect && RouteHelper.isPublic(storedRedirect)) {
      return storedRedirect;
    }

    return internalRoute;
  }

  getDefaultNavigationPage(hasAdfsSession: boolean): PAGES {
    return hasAdfsSession ? DEFAULT_PAGE_WHEN_LOGGED : DEFAULT_PAGE;
  }
}
