import { Component, OnDestroy, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';
import { ConfirmationModalComponent } from '@components/modals/confirmation-modal/confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@services/logger/logger.service';
import { ModalManager } from '@services/modal-manager/modal-manager';
import { NotificationService } from '@services/notification/notification.service';
import { AlertService } from '@app/services';
import { NotificationHandler } from '@shared/helpers/notification/notification.handler';
import { Buttons } from '@shared/models/buttons/buttons';
import { Notification } from '@shared/models/notification/notification.model';

@Component({
  selector: 'app-notifications-popover',
  templateUrl: './notifications-popover.component.html',
  styleUrls: ['./notifications-popover.component.scss']
})
export class NotificationsPopoverComponent implements OnDestroy, OnInit {
  private readonly logger = new Logger('NotificationsPopoverComponent');
  notificationsList!: Notification[];
  unreadNotifications!: number;
  selectedTab!: string;
  private messages!: string[];

  notificationCategories: MSafeAny;
  constructor(
    private notificationService: NotificationService,
    private alertService: AlertService,
    private translate: TranslateService,
    private modalManager: ModalManager
  ) {
    this.getTranslations();
  }

  ngOnDestroy() {
    this.modalManager.dismissAllMatModal();
  }

  ngOnInit() {
    this.getNotifications();
  }

  get sectionMaintenance() {
    return this.notificationService.getSectionMaintenance();
  }

  getNotifications() {
    this.notificationsList = this.notificationService.getStaticNotifications();
  }

  async onSelectTab(event: MSafeAny) {
    this.selectedTab = event.selectedTab;
    this.unreadNotifications = this.unreadNotifications = event.unreadNotifications;
    await domChanges(2000);
  }

  existNotifications() {
    return this.notificationService.getStaticNotifications().length > 0;
  }

  removeNotification(id: string) {
    const title = 'NOTIFICATIONS_PAGE.REMOVE_NOTIFICATION';
    const contents = ['NOTIFICATIONS_PAGE.REMOVE_CONFIRM'];
    const buttons: Buttons[] = [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => this.modalManager.dismissMatModal()
      },
      {
        text: 'ACCEPT',
        type: 'primary',
        enabled: true,
        onClick: () => this.removeNotificationFromList(id)
      }
    ];

    this.modalManager.openMatModal(ConfirmationModalComponent, {
      data: { title, contents, buttons },
      panelClass: ['media-content-modal', 'confirmation-modal']
    });
  }

  notificationAction(notificationPerformed: Notification) {
    NotificationHandler.handle(notificationPerformed);
  }

  private getTranslations() {
    this.translate
      .get(['ERROR_MESSAGES.ERROR', 'NOTIFICATIONS_PAGE.REMOVE_ERROR'])
      .subscribe((messages) => (this.messages = messages));
  }

  private removeNotificationFromList(id: string) {
    this.notificationService
      .removeNotification(id)
      .pipe(
        finalize(() => {
          this.getNotifications();
          this.modalManager.dismissMatModal();
        }),
        catchError((error) => {
          this.alertService.showError(
            this.messages['ERROR_MESSAGES.ERROR'],
            this.messages['NOTIFICATIONS_PAGE.REMOVE_ERROR'],
            3000
          );
          this.logger.error(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
