import { Directive, ElementRef, Renderer2, Input, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
  selector: '[app-animate-item-sliding]'
})
export class AnimateItemSlidingDirective implements OnChanges {
  @Input('app-animate-item-sliding') shouldAnimate!: boolean;

  readonly itemHeight = 110;

  constructor(public element: ElementRef, public renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shouldAnimate.isFirstChange()) {
      return;
    }

    const itemContainer = this.element.nativeElement.firstElementChild;
    const actionButton = this.element.nativeElement.querySelector('.item-options');
    actionButton.style.setProperty('--top-position', `${actionButton.id * this.itemHeight}px`);

    if (this.shouldAnimate) {
      this.renderer.addClass(actionButton, 'sliding-animation-in');
      this.renderer.addClass(itemContainer, 'sliding-animation-in');
      this.renderer.removeClass(actionButton, 'sliding-animation-out');
      this.renderer.removeClass(itemContainer, 'sliding-animation-out');
    } else {
      this.renderer.addClass(actionButton, 'sliding-animation-out');
      this.renderer.addClass(itemContainer, 'sliding-animation-out');
      this.renderer.removeClass(actionButton, 'sliding-animation-in');
      this.renderer.removeClass(itemContainer, 'sliding-animation-in');
    }
  }
}
