import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BankCodeValidationComponent } from '@app/ptrab/components/modals/bank-code-validation/bank-code-validation.component';
import { CodeValidationComponent } from '@app/ptrab/components/modals/code-validation/code-validation.component';
import { PhoneRegistrationComponent } from '@app/ptrab/components/modals/phone-registration/phone-registration.component';
import { PhoneSelectionComponent } from '@app/ptrab/components/modals/phone-selection/phone-selection.component';
import { SecondFaPhoneEditionComponent } from '@app/ptrab/components/modals/second-fa-phone-edition/second-fa-phone-edition.component';
// eslint-disable-next-line max-len
import { SecondFaPhoneSelectionComponent } from '@app/ptrab/components/modals/second-fa-phone-selection/second-fa-phone-selection.component';
import { PhonesResponseInterface } from '@app/ptrab/shared/interfaces/phones-response.interface';
import {
  TwoFactorOperationCode,
  TwoFactorUserStatus,
  TwoFactorValidationResult,
  TwoFactorValidationStatus
} from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Phone } from '@app/ptrab/shared/models/phone';
import { UserPhone } from '@app/ptrab/shared/models/user-phone';
import { Logger, ModalManager, AlertService } from '@app/services';
import { LoadingService } from '@app/services/loading/loading.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { getMinutesFromSeconds } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

import { TwoFactorService } from './two-factor.service';
import { PtrabSessionManager } from '../session/ptrab-session.manager.service';
import { UserService } from '@app/services/user/user.service';
import { SecondFaContactComponent } from '@app/ptrab/components/modals/second-fa-contact/second-fa-contact.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorController {
  private readonly modalOptions: MatDialogConfig = { disableClose: true };
  private logger = new Logger('TwoFactorController');

  constructor(
    private loadingService: LoadingService,
    private modalManager: ModalManager,
    private alertService: AlertService,
    private translateService: TranslateService,
    private twoFactorService: TwoFactorService,
    private ptrabSessionManager: PtrabSessionManager,
    private userService: UserService
  ) {}

  async validateOperation(
    operation: TwoFactorOperationCode | undefined,
    isPortugueseUser: boolean,
    phoneEdit?: boolean
  ): Promise<string> {
    let hasSession = await this.ptrabSessionManager.checkSession();
    if (!hasSession) {
      return Promise.reject(TwoFactorValidationStatus.ERROR);
    }

    const isUserBlocked = await this.assertUserBlocked();
    if (isUserBlocked) {
      return Promise.reject(TwoFactorValidationStatus.USER_BLOCKED);
    }

    let userPhoneList: UserPhone[];
    let modalOptions: MatDialogConfig;

    try {
      userPhoneList = (await this.fetchUserPhoneList().toPromise()) as MSafeAny;
    } catch {
      return Promise.reject(TwoFactorValidationStatus.ERROR);
    }

    if (!userPhoneList.length) {
      if (phoneEdit) {
        return Promise.resolve(TwoFactorOperationCode.REQUEST_OTHER_PHONE);
      }
      modalOptions = {
        ...this.modalOptions,
        data: { isPortugueseUser }
      };
      this.modalManager.openMatModal(PhoneRegistrationComponent, modalOptions);
      return Promise.reject(TwoFactorValidationStatus.CANCELLED);
    }

    const user = await this.userService.getStoredUser();

    modalOptions = {
      ...this.modalOptions,
      data: {
        phones: userPhoneList,
        phoneEdit,
        isPortugueseUser,
        userDiscontinousFixed: user.isDiscontinousFixed
      }
    };

    const phoneId = await this.modalManager.openMatModal(PhoneSelectionComponent, modalOptions);
    if (!phoneId) {
      return Promise.reject(TwoFactorValidationStatus.CANCELLED);
    } else if (phoneId === TwoFactorOperationCode.REQUEST_OTHER_PHONE) {
      return Promise.resolve(TwoFactorOperationCode.REQUEST_OTHER_PHONE);
    } else if (phoneId === TwoFactorOperationCode.REQUEST_OTHER_PHONE_DF) {
      modalOptions = {
        ...this.modalOptions
      };

      await this.modalManager.openMatModal(SecondFaContactComponent, modalOptions);

      return Promise.reject(TwoFactorOperationCode.REQUEST_OTHER_PHONE_DF);
    }

    hasSession = await this.ptrabSessionManager.checkSession();
    if (!hasSession) {
      return Promise.reject(TwoFactorValidationStatus.ERROR);
    }

    modalOptions = {
      ...this.modalOptions,
      data: { phoneId, operation }
    };

    const result: TwoFactorValidationResult = await this.modalManager.openMatModal(
      CodeValidationComponent,
      modalOptions
    );

    return result.status === TwoFactorValidationStatus.SUCCESS
      ? Promise.resolve(result.hash as string)
      : Promise.reject(result.status);
  }

  async validateOperationByAccount(operation: TwoFactorOperationCode): Promise<string> {
    const hasSession = await this.ptrabSessionManager.checkSession();

    if (!hasSession) {
      return Promise.reject(TwoFactorValidationStatus.ERROR);
    }

    const isUserBlocked = await this.assertUserBlocked();
    if (isUserBlocked) {
      return Promise.reject(TwoFactorValidationStatus.USER_BLOCKED);
    }

    const modalOptions: MatDialogConfig = {
      ...this.modalOptions,
      data: { operation },
      panelClass: 'code-validation-modal'
    };

    const result: TwoFactorValidationResult = await this.modalManager.openMatModal(
      BankCodeValidationComponent,
      modalOptions
    );

    return result.status === TwoFactorValidationStatus.SUCCESS
      ? Promise.resolve(result.hash as string)
      : Promise.reject(result.status);
  }

  async validatePtrabLogin(): Promise<string | Phone> {
    let userPhoneList: Phone[];
    let toastMessage: string;
    let modalOptions: MatDialogConfig;

    try {
      const phoneResponse = await this.fetchPhoneList().toPromise();

      userPhoneList = phoneResponse?.phones as Phone[];
      toastMessage = phoneResponse?.message as string;
    } catch {
      return Promise.reject(TwoFactorValidationStatus.ERROR);
    }

    if (!this.hasPhonesAvailable(userPhoneList) && this.hasPhonesEditable(userPhoneList)) {
      modalOptions = {
        ...this.modalOptions,
        data: { phones: userPhoneList }
      };

      const editedPhone: Phone = await this.modalManager.openMatModal(SecondFaPhoneEditionComponent, modalOptions);

      if (!editedPhone) {
        return Promise.reject(TwoFactorValidationStatus.CANCELLED);
      }
      return Promise.resolve(editedPhone);
    }

    const user = await this.userService.getStoredUser();

    modalOptions = {
      ...this.modalOptions,
      data: { phones: userPhoneList, toastMessage }
    };

    const phoneId = await this.modalManager.openMatModal(SecondFaPhoneSelectionComponent, modalOptions);

    if (!phoneId) {
      return Promise.reject(TwoFactorValidationStatus.CANCELLED);
    } else if (phoneId === TwoFactorOperationCode.REQUEST_OTHER_PHONE) {
      modalOptions = {
        ...this.modalOptions,
        data: { phones: userPhoneList }
      };

      const editedPhone: Phone = await this.modalManager.openMatModal(SecondFaPhoneEditionComponent, modalOptions);

      if (!editedPhone) {
        return Promise.reject(TwoFactorOperationCode.LOGIN_2FA);
      } else if (user.isDiscontinousFixed) {
        modalOptions = {
          ...this.modalOptions
        };

        await this.modalManager.openMatModal(SecondFaContactComponent, modalOptions);

        return Promise.reject(TwoFactorOperationCode.REQUEST_OTHER_PHONE_DF);
      }

      return Promise.resolve(editedPhone);
    }

    modalOptions = {
      ...this.modalOptions,
      data: { phoneId, operation: TwoFactorOperationCode.LOGIN_2FA }
    };

    const result: TwoFactorValidationResult = await this.modalManager.openMatModal(
      CodeValidationComponent,
      modalOptions
    );

    if (!result) {
      return Promise.reject(TwoFactorValidationStatus.CANCELLED);
    }

    return result.status === TwoFactorValidationStatus.SUCCESS
      ? Promise.resolve(result.hash as string)
      : Promise.reject(result.status);
  }

  // TODO: Move this check to the guard provider
  async assertUserBlocked() {
    try {
      const userStatus = await this.twoFactorService.getUserStatus().toPromise();
      const isUserBlocked = userStatus?.status === TwoFactorUserStatus.BLOCKED;

      if (isUserBlocked) {
        this.showToastUserIsBlocked(userStatus!.remaining_time);
      }

      return isUserBlocked;
    } catch (error) {
      this.logger.error(error);
      return true;
    }
  }

  private showToastUserIsBlocked(remainingTime: number) {
    this.translateService.get('EMPLOYEE_PORTAL.USER_BLOCKED_TITLE').subscribe((title) => {
      this.translateService
        .get('EMPLOYEE_PORTAL.USER_BLOCKED_INFO', { minutes: getMinutesFromSeconds(remainingTime) })
        .subscribe((description) => {
          this.alertService.showError(title, description);
        });
    });
  }

  private fetchUserPhoneList(): Observable<UserPhone[]> {
    this.loadingService.show();
    return this.twoFactorService.getUserPhoneList().pipe(
      finalize(() => {
        this.loadingService.hide();
      }),
      catchError((error) => {
        this.alertService.showError(
          this.translateService.instant('EMPLOYEE_PORTAL.TWO_FACTOR_TITLE_ERROR'),
          this.translateService.instant('EMPLOYEE_PORTAL.TRY_IN_THE_NEXT_FEW_MINUTES')
        );
        this.logger.error(error);
        return throwError(error);
      })
    );
  }

  private fetchPhoneList(): Observable<PhonesResponseInterface> {
    this.loadingService.show();
    return this.twoFactorService.getPhoneList().pipe(
      finalize(() => {
        this.loadingService.hide();
      }),
      catchError((error) => {
        this.alertService.showError(
          this.translateService.instant('EMPLOYEE_PORTAL.TWO_FACTOR_TITLE_ERROR'),
          this.translateService.instant('EMPLOYEE_PORTAL.TRY_IN_THE_NEXT_FEW_MINUTES')
        );
        this.logger.error(error);
        return throwError(error);
      })
    );
  }

  private hasPhonesAvailable(phones: Phone[]): boolean {
    return phones.some((phone) => phone.sms);
  }

  private hasPhonesEditable(phones: Phone[]): boolean {
    return phones.some((phone) => phone.show_to_edit);
  }
}
